import { GoalStatusTypes } from 'api/src/common/enums'
import dayjs from 'dayjs'

import { CurrentUser } from '@redwoodjs/auth'

import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'

import {
  DateTypes,
  ProgressionTypes,
  SortingOptions,
  StartEndTriggerTypes,
  ViewAsGroups,
} from './constants'
import {
  FormOptions,
  GoalListCellItemType,
  GoalMember,
  GoalMemberTemplateFormOptions,
  GoalOption,
  ViewAsOption,
} from './interfaces'

// sort the goals with the values from the select input
export const sortGoals = <T,>(
  goals: (T & {
    diffDays?: number
    percentProgress?: number
    status?: string
    createdAt?: string
  })[],
  sortBy = SortingOptions.DateCreated,
) => {
  // Shallow copy the array to avoid .sort mutation
  const currentGoals = [...goals]

  // Sort by values - if due date is selected, return 0 i.e. default sort order
  const sortGoalByValue = currentGoals.sort((a, b) => {
    if (sortBy === SortingOptions.DueDate)
      return a.diffDays > b.diffDays ? 1 : -1
    if (sortBy === SortingOptions.Status) return a.status < b.status ? 1 : -1
    if (sortBy === SortingOptions.Progress)
      return a.percentProgress > b.percentProgress ? 1 : -1
    const dateA = new Date(a.createdAt)
    const dateB = new Date(b.createdAt)

    if (sortBy === SortingOptions.DateCreated) return dateA > dateB ? 1 : -1

    return 0
  })

  return sortGoalByValue
}

// filter the goals with the values from searchbar
export const filterGoalsByInput = (
  viewAsOption: ViewAsOption,
  searchTerm: string,
  allGoals: GoalListCellItemType[],
  status: GoalStatusTypes | 'All',
  sortBy: SortingOptions,
) => {
  // Filter by group
  const goalsFilteredByGroup = !viewAsOption
    ? allGoals
    : allGoals.filter((goal) => {
        // Check if the attached membership groups match the search filter
        if (viewAsOption.group === ViewAsGroups.membershipGroup) {
          // Get the Membership Group Ids
          const goalMembershipGroupIds = goal?.aclPrincipals
            .filter((principal) => {
              return principal.principalType === 'MEMBERSHIPGROUP'
            })
            .map((principal) => {
              return principal.principalId
            })
          return goalMembershipGroupIds.includes(viewAsOption?.id)
        }

        // Check if the attached membership match the search filter
        if (viewAsOption.group === ViewAsGroups.membership) {
          // Get the Membership Ids
          const goalMembershipIds = goal?.aclPrincipals
            .filter((principal) => {
              return principal.principalType === 'MEMBERSHIP'
            })
            .map((principal) => {
              return principal.principalId
            })

          return goalMembershipIds.includes(viewAsOption?.id)
        }

        return false
      })

  // Filter by search term
  const filterGoalsBySearchTerm = !searchTerm
    ? goalsFilteredByGroup
    : goalsFilteredByGroup.filter((goal) => {
        if (!goal?.goalTitle) return false
        // Return true if goal title matches search term
        const goalTitleMatchesSearchTerm = goal?.goalTitle
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())

        // Return true if the milestone title matches search term
        const goalMilestonesTitleMatchesSearchTerm = goal?.childGoals?.some(
          (milestone) => {
            return milestone?.goalTitle
              .toLocaleLowerCase()
              .includes(searchTerm.trim().toLocaleLowerCase())
          },
        )

        // Return true if goal description matches the search term
        const goalDescriptionMatchesSearchTerm = goal?.goalBody
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())

        return (
          goalDescriptionMatchesSearchTerm ||
          goalTitleMatchesSearchTerm ||
          goalMilestonesTitleMatchesSearchTerm
        )
      })

  // Filter by status
  // Return true if the goal status matches the selected status
  const filterGoalsByStatus =
    !status || status === 'All'
      ? filterGoalsBySearchTerm
      : filterGoalsBySearchTerm.filter((goal) => goal.currentStatus === status)

  // Sort by value
  // Sort the array based on the value provided by the select input
  const goalsWithSortedMilestones = filterGoalsByStatus.map((goal) => {
    // Sort the milestones by the type
    const sortedMilestones = sortGoals(goal?.childGoals, sortBy)

    return { ...goal, childGoals: sortedMilestones }
  })

  // Sort the parent goals by the type
  const sortedGoals = sortGoals(goalsWithSortedMilestones, sortBy)

  return sortedGoals
}

/**
 * @description Count the number of goals in the goals array. Used for the status/filter bars
 * @param { Goal } goals - All Goals
 * @returns Object with count of each status type
 */
export const countGoals = (goals: GoalListCellItemType[]) => {
  // Count Tally
  const countGoals = {
    all: 0,
    completed: 0,
    inProgress: 0,
    overdue: 0,
    blocked: 0,
  }

  for (const goal of goals) {
    // Increment ALL
    countGoals.all++

    // Increment per status
    if (goal.currentStatus === GoalStatusTypes.completed) countGoals.completed++
    if (goal.currentStatus === GoalStatusTypes.blocked) countGoals.blocked++
    if (goal.currentStatus === GoalStatusTypes.overdue) countGoals.overdue++
    if (goal.currentStatus === GoalStatusTypes.inProgress)
      countGoals.inProgress++
  }

  return countGoals
}

// Structure the goals to be selectable by ID
// This helps quickly change between selected goals
export const setGoalOptions = <T,>(
  goals: (T & { id: number })[],
): GoalOption<T> => {
  // Get all goals in Tuple
  const goalOptions = goals.map((goal) => {
    return [goal.id, goal]
  })

  // Create object from tuple
  const goalObject: GoalOption<T> = Object.fromEntries(goalOptions)

  return goalObject
}

/**
 * Sort View As options by the name param
 */
export const sortViewOptionsByName = (
  viewAsOptions: ViewAsOption[],
): ViewAsOption[] => {
  const sortedViewAsOptions = viewAsOptions.sort((optionA, optionB) => {
    return optionA?.name > optionB?.name ? 1 : -1
  })

  return sortedViewAsOptions
}

export const formatDuration = (number: number, format: string) => {
  return `${number} ${format}${number === 1 ? '' : 's'}`
}

export const getCurrentCourse = (
  courseOptions: LearnerCourseWithStorageObject[],
  currentMilestone: GoalMember,
) => {
  // If nothing attached, return null, don't try to find the course
  if (currentMilestone?.attachedLearnerItemId === null) {
    return null
  }

  // Find the course
  const savedCourse = courseOptions.find((course) => {
    return course?.id === currentMilestone?.attachedLearnerItemId
  })

  // Return course if found, or return null (instead of returning undefined when nothing is found)
  return savedCourse ?? null
}

export const configureFormFields = (
  selectedTemplate: GoalMember,
  parentTemplate: GoalMember,
) => {
  // The almighty form object, my source of truth and one true love xoxo
  // The isGlobal field is selectedTemplate?.isGlobal || false for templates with no parent
  // The isGlobal field is the parentTemplate?.isGlobal for templates with a parent
  // i.e. we want the template milestones to have the same visibility as the parent template

  const isGlobal =
    parentTemplate?.isGlobal || selectedTemplate?.isGlobal || false

  const formFields: FormOptions = {
    id: selectedTemplate?.id || null,
    parentGoalId: selectedTemplate?.parentGoalId || null,
    goalTitle: selectedTemplate?.goalTitle || '',
    goalBody: selectedTemplate?.goalBody || '',
    startDateNumber: selectedTemplate?.startDateNumber || 0,
    startDateFormat: selectedTemplate?.startDateFormat || DateTypes.day,
    startDateTrigger:
      selectedTemplate?.startDateTrigger || StartEndTriggerTypes.objective,
    startDateTriggerMilestoneId:
      selectedTemplate?.startDateTriggerMilestoneId || '',
    dueDateNumber: selectedTemplate?.dueDateNumber || 0,
    dueDateFormat: selectedTemplate?.dueDateFormat || DateTypes.day,
    dueDateTrigger:
      selectedTemplate?.dueDateTrigger || StartEndTriggerTypes.objective,
    dueDateTriggerMilestoneId:
      selectedTemplate?.dueDateTriggerMilestoneId || '',
    templateType: selectedTemplate?.templateType || ProgressionTypes.simple,
    startValue: selectedTemplate?.startValue || 0,
    targetValue: selectedTemplate?.targetValue || 0,
    childGoals: selectedTemplate?.childGoals || [],
    attachedLearnerItemId: selectedTemplate?.attachedLearnerItemId || null,
    isGlobal,
    templateUserRoles: selectedTemplate?.templateUserRoles || [],
  }
  return formFields
}

export const getMilestoneOptions = (
  parentTemplate: GoalMember,
  currentMilestoneId: number,
) => {
  // Get all the milestone options
  const allMilestones = parentTemplate?.orderedMilestones.flatMap((dayObj) => {
    return dayObj?.items.map((milestone) => milestone)
  })

  // Return everything except current milestone
  const milestoneOptions = allMilestones.filter((milestone) => {
    return milestone.id !== currentMilestoneId
  })

  return milestoneOptions
}

export const getParentTemplates = (templates: { parentGoalId?: number }[]) => {
  return templates.filter((template) => template?.parentGoalId === null)
}

export const createTemplateMilestones = (
  selectedTemplate: GoalMember,
  now: dayjs.Dayjs,
) =>
  selectedTemplate?.orderedMilestones.flatMap((dayObj) => {
    return dayObj?.items.map((milestone) => {
      // Set the start and end dates
      const milestoneStart = now.add(dayObj?.dayNumber, 'd')
      const milestoneEnd = milestoneStart.add(
        milestone?.dueDateNumber,
        milestone?.dueDateFormat as dayjs.ManipulateType,
      )

      // Set the start and target values
      const startValue =
        milestone?.templateType === ProgressionTypes.custom
          ? milestone?.startValue
          : 0

      const targetValue =
        milestone?.templateType === ProgressionTypes.custom
          ? milestone?.targetValue
          : 0

      const learnerCourseId =
        milestone?.templateType === ProgressionTypes.learner
          ? milestone?.attachedLearnerItemId
          : null

      return {
        id: milestone?.id,
        goalTitle: milestone?.goalTitle,
        goalBody: milestone?.goalBody,
        startDate: milestoneStart,
        dueDate: milestoneEnd,
        startValue: startValue,
        targetValue: targetValue,
        templateId: selectedTemplate?.id,
        templateType: milestone?.templateType,
        attachedLearnerItemId: learnerCourseId,
      }
    })
  })

export const createChildGoalsInput = (
  templateFormFields: GoalMemberTemplateFormOptions,
  currentUser: CurrentUser,
  parentGoalId: number,
) =>
  templateFormFields?.childGoals.map((milestone) => {
    return {
      clientId: currentUser.parentData.id,
      parentGoalId,
      goalTitle: milestone?.goalTitle ?? '',
      goalBody: milestone?.goalBody ?? '',
      startDate: (milestone?.startDate ?? dayjs()).format(),
      dueDate: (milestone?.dueDate ?? dayjs().add(14, 'd')).format(),
      startValue: milestone?.startValue ?? 0,
      targetValue: milestone?.targetValue ?? 0,
      templateType: milestone?.templateType ?? ProgressionTypes.simple,
      templateId: milestone?.id,
      isComplete: false,
      status: 'In Progress',
      attachedLearnerItemId: milestone?.attachedLearnerItemId ?? null,
      currentValue: milestone?.startValue ?? 0, // current value must equal start value, so the goal/milestone can be 0% complete on create.
    }
  })

export const setTemplateOptions = (
  templates: GoalMember[],
): { [key: number]: GoalMember } => {
  const options = {}

  for (const template of templates) {
    options[template.id] = template
  }

  return options
}
