export enum ViewAsGroups {
  membership = 'Membership',
  membershipGroup = 'Membership Group',
}

export enum StatusTextColors {
  'Completed' = 'text-green-600',
  'In Progress' = 'text-blue-600',
  'Blocked' = 'text-gray-600',
  'Overdue' = 'text-red-600',
}
export enum StatusBGColors {
  'Completed' = 'bg-green-100',
  'In Progress' = 'bg-blue-100',
  'Blocked' = 'bg-gray-100',
  'Overdue' = 'bg-red-100',
}

export enum StatusBGColorsDark {
  'Completed' = 'bg-green-600',
  'In Progress' = 'bg-blue-600',
  'Blocked' = 'bg-gray-600',
  'Overdue' = 'bg-red-600',
}

export enum EditFormStates {
  empty = 'empty',
  edit = 'edit',
  new = 'new',
}

export enum DateTypes {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export enum ProgressionTypes {
  simple = 'simple',
  custom = 'custom',
  milestone = 'milestone',
  learner = 'learner',
}

export enum ProgressionTypesLabels {
  simpleGoal = 'Simple Goal',
  simpleMilestone = 'Simple Milestone',
  customGoal = 'Numerical Goal',
  customMilestone = 'Custom Milestone',
  milestone = 'Goal With Milestones',
  learner = 'Link To Course',
}

export enum ErrorTypes {
  loop = 'The start day for this Milestone could not be calculated. This happens when you have two milestones that depend on each other. For example, milestone 1 starts at the completion of Milestone 2; however milestone 2 is set to start at the completion of milestone 1. To fix this, please change the trigger so that the milestones are not reliant on each other.',
}

export enum ProgressionTypeDefinitions {
  simpleGoal = 'A simple goal, managed by changing status between Completed, In Progress or Blocked.',
  simpleMilestone = 'A simple milestone, managed by changing status between Completed, In Progress or Blocked.',
  customGoal = 'A customised goal that calculates your progress based on Start, Current and Target values that you set.',
  customMilestone = 'A customised milestone that calculates your progress based on Start, Current and Target values that you set.',
  milestone = 'An advanced goal that allows you to set milestones to help you achieve your goal! The progress of the goal is based on the overall progress of your milestone progression.',
  learner = 'Link the milestone to a Learner Course or Learner Lesson. The progress of this milestone will be calculated from your progress through the selected course or lesson.',
}

export enum StartEndTriggerTypeTitles {
  objectiveStart = 'Start with the Main Goal',
  milestoneStart = 'Start simultaneously with another Milestone',
  milestoneEnd = 'Start at completion of another Milestone',
}

export enum StartEndTriggerTypes {
  objective = 'objective',
  milestoneStart = 'milestoneStart',
  milestoneEnd = 'milestoneEnd',
  selfStart = 'selfStart',
}

export enum StartEndTriggerTypesDefinitions {
  objective = 'The milestone will start as soon as the goal has started.',
  milestoneStart = 'The milestone will start when another Milestone of your choice also starts.',
  milestoneEnd = 'The milestone will only start when another milestone of your choice has been completed.',
  // eslint-disable-next-line quotes
  selfStart = "A specified amount of time from this milestone's start date.",
}

export enum SortingOptions {
  All = 'All',
  DateCreated = 'Date Created',
  DueDate = 'Due Date',
  Progress = 'Progress',
  Status = 'Status',
}
